import { MediaViewerElement } from "./MediaViewerElement";
import "./PostcardViewerElement.less";

export class PostcardViewerElement extends HTMLElement { 
    static tag = "postcard-viewer";

    connectedCallback() { 
        this.innerHTML = this.view();   
        // this.style.backgroundImage = `url(${this.getAttribute("path")})`; 
        this.addEventListener("click", this.onClick);
    }

    private onClick = () => { 
        var mediaViewer = new MediaViewerElement();
        mediaViewer.setAttribute("scaled-url", this.getAttribute("path"));
        mediaViewer.setAttribute("original-url", this.getAttribute("path"));
        document.body.appendChild(mediaViewer);
    }

    private view = () => `
        <div name=image style="background-image: url(${this.getAttribute("path")})"></div>
        ${require("./Images/zoom.svg")}
    `;
}

customElements.define(PostcardViewerElement.tag, PostcardViewerElement);
