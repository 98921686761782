export class VisitorStore { 
    private static _instance = new VisitorStore();
    static get instance() { 
        return VisitorStore._instance;
    }

    public get visitorSessionId() { 
        let visitorSessionId = sessionStorage.getItem("visitorSessionId");
        if (!visitorSessionId) {
            visitorSessionId = VisitorStore.createId();
            sessionStorage.setItem("visitorSessionId", visitorSessionId);
        }
        return visitorSessionId;
    }


    public get visitorId() { 
        let visitorId = localStorage.getItem("visitorId");
        if (!visitorId) {
            visitorId = VisitorStore.createId();
            localStorage.setItem("visitorId", visitorId);
        }
        return visitorId;
    }

    private static createId(): string { 
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        return `${s4()}${s4()}${s4()}`;
    }
}