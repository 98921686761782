// import * as StackTrace from "stacktrace-js";

export interface ErrorConfig 
{
    apiKey: string;
    logServer: string;
}

type LogEventLevel = "verbose" | "debug" | "information" | "warning" | "error" | "fatal"

export class LogStore {
    private _decorators: ((properties: { [id: string] : any; }) => Promise<void>)[] = [];
    private htmlEscape = (str) => String(str).replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");

    decorate(decorator: (properties: { [id: string] : any; }) => Promise<void>) : LogStore {
        this._decorators.push(decorator);
        return this;
    }
    private static _instance = new LogStore();
    private constructor() {}
    static get instance() { return LogStore._instance; }
    private _config: ErrorConfig; 

    configure(config: ErrorConfig) {
        this._config = config;
    }
    async logInformation(message: string, properties?: { [id: string] : any; } ) : Promise<void>;
    async logInformation(logObject: string, properties?: { [id: string] : any; } ) : Promise<void>
    {
        await this.log("information", logObject, properties);
    }
    
    async logError(message: string, properties?: { [id: string] : any; } ) : Promise<void>;
    async logError(error: Error, properties?: { [id: string] : any; } ) : Promise<void>;
    async logError(logObject: string | Error, properties?: { [id: string] : any; } ) : Promise<void>
    {
        await this.log("error", logObject, properties);
    }

    async log(level: LogEventLevel, logObject: string | Error, properties?: { [id: string] : any; } ) : Promise<void> {
        properties = properties || {};

        if (this._decorators.length > 0)
            await Promise.all(this._decorators.map(async m => await m(properties)));

        let message = "";

        if (logObject instanceof Error) 
        {
            const error = logObject as Error;
            properties.error = {
                columnNumber: (<any>error).columnNumber,
                fileName: (<any>error).fileName,
                lineNumber: (<any>error).lineNumber,
                message: error.message,
                name: error.name
            }
            
            properties.stacktrace = error.stack;

            // const enhanced =  await StackTrace.fromError(error);
            // properties.enhancedStack = enhanced.map(m => this.htmlEscape(m.toString())).join("\n");


            message = error.message
        } else {
            message = logObject;
        }

        const errorDetails = {
            messageTemplate: message,
            level: level,
            properties: {
                ... properties,
                _userAgent: navigator.userAgent,
                _requestUrl: window.location.href,
                cookieEnabled: navigator.cookieEnabled,
                clientDate: new Date()
            }
        };

        try{
            const response = await fetch(this._config.logServer, { 
                method: "post", 
                headers: { "Content-Type": "application/json", "api-key": this._config.apiKey }, 
                body: JSON.stringify([errorDetails])
            });
            if (response.ok)
                console.log("Logged", await response.json());
        } catch(error){
            console.log(`Unable to log error. ${error}`)
        }
        
    }
}