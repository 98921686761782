import "./DiscountCodeElement.less";
import { Order, OrderStore } from "Feature/Ordering/OrderStore";
import { LoaderButtonElement } from "Element/LoaderButtonElement";

export class DiscountCodeElement extends HTMLElement { 
    static tag = "discount-code";
    _button: HTMLButtonElement;
    _input: HTMLInputElement;
    connectedCallback() { 
        const order = OrderStore.instance.getOrder();
        this.toggleAttribute("hidden", OrderStore.hasDiscountCode(order));

        this.toggleAttribute("completed", order.completed);

        this.innerHTML = this.view();
        this._button = this.querySelector(LoaderButtonElement.tag);
        this._input = this.querySelector("input");

        this._input.addEventListener("keyup", this.onKeyup);
        this._button.addEventListener("click", this.onClick);

        OrderStore.instance.addEventListener("refresh", this.onRefresh);
    }

    disconnectedCallback() { 
        OrderStore.instance.removeEventListener("refresh", this.onRefresh);
    }

    private onRefresh = (event: CustomEvent<Order>) => { 
        this.toggleAttribute("hidden", OrderStore.hasDiscountCode(event.detail));
        this.toggleAttribute("completed", event.detail.completed);
    }

    private onKeyup = (event: KeyboardEvent) => { 
        if (event.key === "Enter") { 
            this.onClick();
        }
    }

    private onClick = async () => { 
        this.querySelector("p")?.remove();

        if (!this.hasAttribute("open")) { 
            this.toggleAttribute("open", true);
            this._input.focus();
            return;
        }

        if (this._input.value.length === 0) return;

        this._button.toggleAttribute("busy", true);
        this._button.disabled = true;
        this._input.disabled = true;

        let response: string;

        try {
            response = await OrderStore.instance.addDiscountCode(this._input.value);
        } finally { 
            this._button.disabled = false;
            this._input.disabled = false;
            this._button.removeAttribute("busy");
        }

        this.insertAdjacentHTML("afterbegin", `
            <p>${response}</p>&nbsp;&nbsp;
        `);

        this._input.value = "";
        this.toggleAttribute("open", false);
    }

    private view = () => `
        <input type="text" name="discount-code" placeholder="Discount code" />
        <${LoaderButtonElement.tag} text="Add discount code"></${LoaderButtonElement.tag}>
    `;
}

customElements.define(DiscountCodeElement.tag, DiscountCodeElement);