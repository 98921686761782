import "./FormGroupElement.less";

export class FormGroupElement extends HTMLElement {
    public static tag = "form-group";
    private name = () => this.getAttribute("name");
    private label = () => this.getAttribute("label");
    private pattern = () => this.getAttribute("pattern");
    private minlength = () => this.getAttribute("minlength");
    private maxlength = () => this.getAttribute("maxlength");
    private named = <T extends HTMLElement> (name: string): T => this.querySelector(`[name=${name}]`) as T;
    private inputElement: HTMLInputElement;
    private errorText: HTMLElement;


    static get observedAttributes() {
        return ["error-text"];
    }

    connectedCallback() {
        // this.style.display = "contents";
        this.innerHTML = this.view();
        
        this.inputElement = this.named(`${this.name()}-input`);
        this.errorText = this.named(`${this.name()}-message`);
        this.inputElement.addEventListener("blur", this.validateHandler);  
        this.inputElement.addEventListener("blur", this.blurHandler);  
        this.inputElement.addEventListener("input", this.inputHandler);
    }

    private blurHandler = () => {
        if (!this.hasAttribute("blurred")) {
            this.inputElement.setAttribute("blurred", "");
            this.setAttribute("blurred", "");
        }
    }

    attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === "error-text") 
            this.setValidity(newVal);
    }

    focus(options?: FocusOptions): void {
        this.inputElement.focus(options);
    }
    

    private inputHandler = async () => {
        this.setAttribute("value", this.inputElement.value);
        await this.validate(true); 
    }

    private setValidity(message: string) {
        this.errorText.innerText = message || "";
        this.inputElement.setCustomValidity(message || "");
        this.dispatchEvent(new CustomEvent("validity", { bubbles: true }));
    }

    private validateHandler = async () => {
        await this.validate(false);
    }

    private validate = async (positiveFlowOnly = false) => {
        if (!this.hasAttribute("touched")) { //&& !positiveFlowOnly
            this.inputElement.setAttribute("touched", "");
            this.setAttribute("touched", "");
            // this.inputElement.addEventListener("input", this.validateHandler);
        }
     
        let error = "";

        if (this.inputElement.validity.tooShort)
            error = `${this.label()} should be longer than ${this.inputElement.minLength} characters.`;
        
        if (this.inputElement.validity.tooLong)
            error = `${this.label()} should be shorter than ${this.inputElement.maxLength} characters.`;

        if (this.inputElement.validity.patternMismatch)
            error = `${this.label()} contains invalid characters.`;

        if (positiveFlowOnly && error !== "")
            return;
        
        
        if (error === "") {
            this.dispatchEvent(new CustomEvent("validate", { detail: this.inputElement.value }));
            this.setAttribute("value", this.inputElement.value);

            error = this.getAttribute("error-text");
        }

        this.setValidity(error);
    };

    private view = () => `
        <label for="${this.name()}">
            ${this.label()}
        </label>


        <input
            id="${this.name()}"
            name="${this.name()}-input"
            ${this.pattern() ? `pattern="${this.pattern()}"` : ""}"
            value="${this.getAttribute("value") || ""}"
            minlength=${this.minlength() || ""}
            maxlength=${this.maxlength() || ""}
            autocomplete=${this.getAttribute("autocomplete") || ""}
            autocapitalize=none
            autocorrect=off
            type=${this.getAttribute("type") || "text"}
            required
        />

        <span name="${this.name()}-message" class="error-text"></span>
    `;
}

customElements.define(FormGroupElement.tag, FormGroupElement);