import { logStore } from "./Features/Logging";
import { UrlProvider } from "./Library/UrlProvider";

const apiKeys = {
    "local": "logkey_01h6v1gfcxfkctm5g8wz2hz493",
    "test": "logkey_01h6v1j62zebeb350pt0dxfqyh",
    "production": "logkey_01h6v1jb17f06tkm9r1dxch1zs"
}

logStore.configure({
    apiKey: apiKeys[UrlProvider.environment],
    //logServer: "http://localhost:36250/api/ingest"
    logServer: "https://watson.zebrasoft.dk/api/ingest"
});

import "./Features";