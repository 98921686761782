import { OrderStore } from "../Ordering/OrderStore";
import "./StripePaymentElement.less";

declare var Stripe: any;

export class StripePaymentElement extends HTMLElement {
    static tag = "stripe-payment";


    private async initialize() {
        await new Promise<void>((resolve) => {
            const script = document.createElement("script");
            document.head.appendChild(script);
            script.src = "https://js.stripe.com/v3/";
            script.onload = async () => {
                resolve(undefined);
            }
        });
    }

    static show(stripeKey: string) {
        const element = new StripePaymentElement();
        element.setAttribute("stripe-key", stripeKey);
        document.body.appendChild(element);
    }

    private async initializeCheckout() {
        const order = OrderStore.instance.getOrder();
        const response = await fetch(`/api/stripe/${order.id}`, {
            method: "POST",
            body: JSON.stringify({


            }),
            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            const { clientSecret } = await response.json();

            return clientSecret;
        }
    }

    async connectedCallback() {
        const stripe = Stripe(this.getAttribute("stripe-key"));

        this.innerHTML = this.view();

        const clientSecret = await this.initializeCheckout();

        const checkout = await stripe.initEmbeddedCheckout({
            clientSecret,
        });

        checkout.mount('#checkout');
    }

    private view = () => `
        <div id="checkout">
            <!-- Checkout will insert the payment form here -->
        </div>
    `;
}


customElements.define(StripePaymentElement.tag, StripePaymentElement);