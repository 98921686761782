import { Order, Orderline, OrderStore } from "./OrderStore";
import "./OrderViewerElement.less"

export class OrderViewerElement extends HTMLElement { 
    static tag = "order-viewer";

    connectedCallback() { 
        OrderStore.instance.addEventListener("refresh", this.onRefresh);
        this.addEventListener("click", this.onClick);
        (async () => await this.connectedCallbackAsync())();
    }

    disconnectedCallback() { 
        OrderStore.instance.removeEventListener("refresh", this.onRefresh);
        this.removeEventListener("click", this.onClick);
    }

    protected async connectedCallbackAsync() { 
        const order = OrderStore.instance.getOrder();
        this.innerHTML = this.view(order);
    }

    private onClick = async (event: MouseEvent) => { 
        if (!(event.target instanceof Element))
            return;

        const code = event.target.getAttribute("data-code");
        if (!code)
            return;

        await OrderStore.instance.removeDiscountCode(code);
    }

    private onRefresh = (event: CustomEvent<Order>) => { 
        this.innerHTML = this.view(event.detail);
    }

    private amountView = (amount: number) => `
        <span>EUR</span> ${(amount / 100).toFixed(2)}
    `

    private productLineView = (orderline: Orderline) => `
        <div class=line>
            <div class=description>
                <b>${orderline.name}</b><br />
                ${orderline.description}
            </div>
            <div class="price">
                ${this.amountView(orderline.amount)}
            </div>
        </div>
    `

    private discountLineView = (order: Order, orderline: Orderline) => `
        <div class=line>
            <div class=description>
                Discount code *${orderline.discountCodeId}* 
                ${!order.completed ? `
                    <button type=button name=remove-discount data-code="${orderline.discountCodeId}">(remove)</button>
                `: ""}
            </div>
            <div class="price">
                ${this.amountView(orderline.amount)}
            </div>
        </div>
    `

    private deliveryView = (order: Order) => { 
        if (order.completed) {
            return `
                <div class=delivery>
                    <b>Game code ${order.gameCode}</b>
                    <span>Delivered to&nbsp;${order.email}</span>
                    <a href="${order.gameCodeUrl}" target="_blank">Start game now</a>
                </div>
            `;
        } else { 
            if (order.email) {
                return `
                    <div class=delivery>    
                        Deliver to&nbsp;${order.email}
                    </div>
                `;
            }
        }

        return "";
    }

    private orderlineView = (order: Order, orderline: Orderline) => orderline.discountCodeId ? this.discountLineView(order, orderline) : this.productLineView(orderline);

    private view = (order: Order) => `
       
            <div class=box>
            ${order.orderlines.map(orderLine => this.orderlineView(order, orderLine)).join("")}

        

            <div class=total>
                <div class=description>
                    Total
                </div>
                <div class="price">
                    ${this.amountView(order.total)}
                </div>
            </div>

            
           

            ${order.completed && order.total > 0 ? `
            <div class=payment>    
                <div class=description>
                    ${order.cardType ? `
                        <b>Creditcard payment&nbsp;</b><br &>
                        <span>${order.cardType.toUpperCase()} XXXX XXXX XXXX ${order.last4}</span>
                    ` : ""}
                </div>
                <div class="price">
                    <b>${this.amountView(order.total)}</b>
                </div>
            </div>
            ` : ""}

             ${this.deliveryView(order)}
        </div>
    `;
}

customElements.define(OrderViewerElement.tag, OrderViewerElement);