import "./GameGalleryElement.less";

interface GalleryItem {
    title: string;
    source: string;
    preview: string;
}

export class GameGalleryElement extends HTMLElement { 
    static tag = "game-gallery";
    private _items: GalleryItem[] = [];
    private _viewElement: HTMLElement;


    connectedCallback() { 
        document.querySelectorAll("img").forEach((element) => { 

            const item: GalleryItem = {
                title: element.getAttribute("alt") || "",
                source: element.getAttribute("large") ||  element.getAttribute("src") || "",
                preview: element.getAttribute("src") || ""
            };

            this._items.push(item);

        });

        this.innerHTML = this.view();
        this._viewElement = this.querySelector("[name=view]");
        this.addEventListener("click", this.onClick);
    }

    private onClick = (event: MouseEvent) => { 
        if (!(event.target instanceof HTMLElement))
            return;

        const button = event.target.closest("button");
        if (!button)
            return;

        const index = parseInt(button.dataset.index || "0");

        this._viewElement.setAttribute("src", this._items[index].source);
        this._viewElement.setAttribute("alt", this._items[index].title);
        this._viewElement.setAttribute("title", this._items[index].title);

        const currentSelected = this.querySelector("button[selected]");
        currentSelected?.removeAttribute("selected");

        button.toggleAttribute("selected", true);
    }


    private view = () => `
        <img src="${this._items[0].source}" alt="${this._items[0].title}" name=view title="${this._items[0].title}" />
        <div name=selector>
            ${this._items.map((item, index) => `
                <button ${index == 0 ? " selected" : ""} data-index="${index}" title="${item.title}">
                    <img src="${item.preview}" alt="${item.title}" />
                </button>
            `).join("")}
        </div>
    `;
}

customElements.define(GameGalleryElement.tag, GameGalleryElement);

