import "./FlipButtonElement.less";
import { Order, OrderStore } from "./OrderStore";

class FlipButtonElement extends HTMLElement { 
    static tag = "flip-button";

    connectedCallback() { 
        const order = OrderStore.instance.getOrder();

        if (!OrderStore.instance.showGiftCardOption(order))
            return;

        this.toggleAttribute("completed", order.completed);
        this.innerHTML = this.view(order.isGift);
        this.addEventListener("click", this.onClick);

        OrderStore.instance.addEventListener("refresh", this.onRefresh);
    }

    disconnectedCallback() { 
        this.removeEventListener("click", this.onClick);

        OrderStore.instance.removeEventListener("refresh", this.onRefresh);
    }

    private onRefresh = (event: CustomEvent<Order>) => { 
        this.toggleAttribute("completed", event.detail.completed);
    }

    private onClick = async (event: MouseEvent) => { 
        if (!(event.target instanceof Element))
            return;

        const button = event.target.closest("button");
        if (!button)
            return;

        if (button.hasAttribute("active"))
            return;

        this.querySelectorAll("button").forEach(button => button.toggleAttribute("active", false));

        button.toggleAttribute("active", true)
        
        const isGift = button.name === "gift";
        
        await OrderStore.instance.update(order => order.isGift = isGift);
    }

    private view = (isGift: boolean) => `
        <button type=button ${isGift ? "" : "active"} name="self">Buy for myself</button>
        <button type=button ${!isGift ? "" : "active"} name="gift">Buy as a gift</button>
    `;
}

customElements.define(FlipButtonElement.tag, FlipButtonElement);