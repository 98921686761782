import "./OrderDetailsElement.less";
import { Order, OrderStore } from "Feature/Ordering/OrderStore";
import { LoaderButtonElement } from "Element/LoaderButtonElement";

export class OrderDetailsElement extends HTMLElement { 
    public static readonly tag = "order-details";
    private _button: HTMLButtonElement;
    private _input: HTMLInputElement;
    private _fieldset: HTMLFieldSetElement;

    connectedCallback() { 
        const order = OrderStore.instance.getOrder();
        this.innerHTML = this.view();
        this.toggleAttribute("completed", order.completed);

        this._fieldset = this.querySelector("fieldset");


        if (OrderStore.hasEmail(order)) {
            this.showReadonlyEmail();
        } else { 
            this.showChangableEmail();
        }

        this.addEventListener("click", this.onClick);
        OrderStore.instance.addEventListener("refresh", this.onRefresh);
    }

    disconnectedCallback() { 
        OrderStore.instance.removeEventListener("refresh", this.onRefresh);
    }

    private onRefresh = (event: CustomEvent<Order>) => { 
        const order = OrderStore.instance.getOrder();   
        
        if (OrderStore.hasEmail(order)) {
            if (this._input)
                this.showReadonlyEmail();
        } else { 
            if (!this._input)
                this.showChangableEmail();
        }

        this.toggleAttribute("completed", order.completed);

        // this.toggleAttribute("hidden", OrderStore.hasDetails(event.detail) || event.detail.isGift);
    }

    private onInputKeyup = (event: KeyboardEvent) => { 
        if (event.key === "Enter") { 
            this.updateEmail();
        }
    }

    private showReadonlyEmail = () => { 
        const email = OrderStore.instance.getOrder().email;
        this._fieldset.innerHTML = this.emaiReadonlyView(email);

        this._input = undefined;
    }

    private showChangableEmail = () => { 
        const email = OrderStore.instance.getOrder().email;
        this._fieldset.innerHTML = this.emailEditableView(email);
        this._input = this.querySelector("input");
        this._button = this.querySelector(LoaderButtonElement.tag);
        this._input.addEventListener("keyup", this.onInputKeyup);
       
        this._input.focus();
        this._input.select();
    }

    private updateEmail = async () => { 
        this.querySelector("p")?.remove();
        if (this._input.value.length === 0) return;

        this._button.toggleAttribute("busy", true);
        this._button.disabled = true;
        this._input.disabled = true;

        let response: string;

        try {
            response = await OrderStore.instance.update(order => order.email = this._input.value);
            // response = await OrderStore.instance.addDetails(this._input.value);
        } finally { 
            this._button.disabled = false;
            this._input.disabled = false;
            this._button.removeAttribute("busy");
        }

        // this.insertAdjacentHTML("afterbegin", `
        //     <p>${response}</p>&nbsp;&nbsp;
        // `);

        this._input.value = "";
        this.toggleAttribute("open", false);
    }

    private onClick = async (event: Event) => { 
        if (!(event.target instanceof HTMLElement))
            return;

        if ((event.target as HTMLButtonElement).name === "change-email") {
            this.showChangableEmail();
        } 

        if (event.target.closest("loader-button")) {
            this.updateEmail();
        }
    }

    private emaiReadonlyView = (email: string) => `
        <span>${email}</span><button name=change-email type="button">Change</button>
    `;

    private emailEditableView = (email: string = "") => `
        <label>Please enter your email address</label>
        <input type=email placeholder="someone@somewhere.com" autofocus value="${email || ""}" />
        <${LoaderButtonElement.tag} name=continue text="Continue"></${LoaderButtonElement.tag}>
    `;

    private view =  () => `
        <div class="box details">
            After payment, we will send you a receipt and a game code to your email address. You can then use this code to start the game at any time.
        
            <fieldset>
                 
            </fieldset>
            
        </div>
    `;
}

customElements.define(OrderDetailsElement.tag, OrderDetailsElement);