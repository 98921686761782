import "./SiteFooterElement.less";

class SiteFooter extends HTMLElement { 
    static tag = "site-footer";

    connectedCallback() { 
        if (this.hasAttribute("rendered")) return;
        this.innerHTML = this.view();
    }

    private view = () => `
        <div class=tag-line>
            Zebra Escapes specializes in engaging and entertaining digital online escape rooms for multiplayer experiences, where players collaborate to solve puzzles and uncover mysteries, while having fun and enjoying the thrill of the adventure.
        </div>

        <div class=links>
            <a href="/privacy">Privacy Policy</a>&nbsp;&nbsp;&nbsp;&middot;&nbsp;&nbsp;&nbsp;<a href="/terms">Terms of Service</a>&nbsp;&nbsp;&nbsp;&middot;&nbsp;&nbsp;&nbsp;<a href="/support">Support</a>
        </div>

        <div class=copyright>
            &copy; 2023 Zebra Escapes
        </div>
    `;
}

customElements.define(SiteFooter.tag, SiteFooter);