import "./SiteNavigationElement.less";

class SiteNavigationElement extends HTMLElement {
    public static tag = "site-navigation";
    connectedCallback() { 
        if (this.hasAttribute("keep-small"))
            return;

        document.addEventListener("scroll", this.scrollHandler, { passive: true });
        document.addEventListener("pointerup", this.scrollHandler, { passive: true });
    }

    disconnectedCallback() { 
        document.removeEventListener("scroll", this.scrollHandler);
        document.removeEventListener("pointerup", this.scrollHandler);
    }

    private scrollHandler = () => { 
        const scrollPos = Math.max(document.documentElement.scrollTop, window.scrollY);
        this.toggleAttribute("small", scrollPos > 90);
    }
}

customElements.define(SiteNavigationElement.tag, SiteNavigationElement);

