import "./MediaViewerElement.less";
import * as Hammer from "hammerjs";

export class MediaViewerElement extends HTMLElement {
    static tag = "media-viewer";
    private hammer: Hammer.HammerManager; 
    private scaledUrl = () => this.getAttribute("scaled-url");
    private originalUrl = () => this.getAttribute("original-url");

    private imageElement: HTMLImageElement;   
    private currentScale: number = 1;    
    private left: number = 0;
    private top: number = 0;
    private panStarted = undefined;
    private largeLoaded = false;

    async connectedCallback() {
        this.innerHTML = this.view();

        requestAnimationFrame(() => {
            if ((<any>window).StatusBar)
                (<any>window).StatusBar.hide();

            requestAnimationFrame(() => this.setAttribute("open", ""));
        });

        this.imageElement = this.querySelector("img");
        this.hammer = new Hammer(this.imageElement);
        this.hammer.add([new Hammer.Pinch(), new Hammer.Pan()]);
        this.hammer.on("pan", this.panHandler);
        this.hammer.on("panend", this.panEndHandler);
        this.hammer.on("pinch", this.pinchHandler);
        this.hammer.on("pinchend", this.pinchEndHandler);
        this.hammer.on("tap", this.onTap);

        //this.addEventListener("wheel", this.onWheel);

        this.querySelector("button[name=close]").addEventListener("click", this.closeHandler);
        await Promise.all([this.loadSmallImage(), this.loadLargeImage()]);
    }

    disconnectedCallback() {
        if (this.hammer)
            this.hammer.destroy();
    }

    private onWheel = (event: WheelEvent) => { 
        event.preventDefault();
        event.stopPropagation();
        this.currentScale -= event.deltaY / 1000;
        this.refresh();
    }

    private onTap = () => {
        if (this.currentScale >= 2)
            this.currentScale = 1;
        else
            this.currentScale = 2;


        this.refresh();
    };
    
    
    private loadSmallImage = async () => {
        await this.tryLoadImage(this.scaledUrl());
        if (this.largeLoaded)
            return;
        
        this.imageElement.src = this.scaledUrl();
    }

    private loadLargeImage = async () => {
        await this.tryLoadImage(this.originalUrl());
        this.largeLoaded = true;

        this.imageElement.src = this.originalUrl();
    }

    private async tryLoadImage(url: string): Promise<{}> {
        return new Promise(resolve => {
            const image = new Image();

            image.onerror = () => resolve(undefined);
            image.onload = () => resolve(undefined);
            image.src = url;
        });
        
    }

    private refresh = (forced: boolean = false) => {
        if (forced && (<any>window).TapticEngine) 
            (<any>window).TapticEngine.unofficial.weakBoom();

        this.setAttribute("scaled", this.currentScale.toString());

        this.imageElement.style.transform = `scale3d(${this.currentScale}, ${this.currentScale}, 1) translate(${this.left}px, ${this.top}px)`;

        if (this.currentScale >= 2)
            this.imageElement.style.paddingTop = "200px";
        else
            this.imageElement.style.paddingTop = "";
        // this.imageElement.style.paddingTop = `${this.imageElement.height / this.imageElement.width * 100}%`;
    };

    private panHandler = (event: Hammer.TouchInput) => {
        if (!this.panStarted) {
            this.panStarted = { x: this.left, y: this.top };
        }
 
        this.left = this.panStarted.x + event.deltaX / this.currentScale;
        this.top = this.panStarted.y + event.deltaY / this.currentScale;
 
        this.refresh();
    };

    private panEndHandler = (event: Hammer.TouchInput) => {
        this.panStarted = undefined;

        let refresh = false;
        const maxLeft = ((this.currentScale - 1) * this.imageElement.width) / (this.currentScale * 2);
        const maxTop = ((this.currentScale - 1) * this.imageElement.height) / (this.currentScale * 2);

        if (event.velocityY < -1)
            this.closeHandler();

        if (this.left > maxLeft) {
            this.left = maxLeft;
            refresh = true;
        }

        if (this.left < maxLeft*-1) {
            this.left = maxLeft*-1;
            refresh = true;
        }
        
        if (this.top > maxTop) {
            this.top = maxTop;
            refresh = true;
        }

        if (this.top < maxTop*-1) {
            this.top = maxTop*-1;
            refresh = true;
        }
        
        if (refresh) 
            this.refresh();  
    };

    private pinchEndHandler = (event) => {
        let refresh = false;
        
        if (this.currentScale < 1) {
            this.currentScale = 1;
            this.left = 0;
            this.top = 0;
            refresh = true;
        }
        
        if (refresh) 
            this.refresh();
    };

    private pinchHandler = (event) => {
        this.currentScale = event.scale;
        this.refresh();
    };

    private closeHandler = () => {
        this.setAttribute("closing", "");
        
        if ((<any>window).StatusBar)
            (<any>window).StatusBar.show();
            
        setTimeout(() => {
            this.remove();
        }, 500);
    }

    private view = () => `
        <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" />
        <button type=button name=close>${require("./Images/close.svg")}</button>
    `;
}

customElements.define(MediaViewerElement.tag, MediaViewerElement);

