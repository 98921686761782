import "./QuickInfoElement.less";

export class QuickInfoElement extends HTMLElement { 
    static tag = "quick-info";

    connectedCallback() { 
        this.innerHTML = this.view();
    }

    private view = () => `
        <div class=info>
            <div class=title>Estimated time</div>
            <div class=data>${require("./Images/time-icon.svg")} ${this.getAttribute("time")}</div>
        </div>

        <div class=info name=difficulty>
            <div class=title>Difficulty level</div>
            <div class=data difficulty=${this.getAttribute("difficulty")}>
                ${require("./Images/lock-icon.svg")}
                ${require("./Images/lock-icon.svg")}
                ${require("./Images/lock-icon.svg")}
                ${require("./Images/lock-icon.svg")}
                ${require("./Images/lock-icon.svg")}
            </div>
        </div>

        <div class=info>
            <div class=title>Recommended players</div>
            <div class=data>${require("./Images/players-icon.svg")} ${this.getAttribute("players")}</div>
        </div>
    `;
}

customElements.define(QuickInfoElement.tag, QuickInfoElement);