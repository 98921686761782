import "./LoaderButtonElement.less";

export class LoaderButtonElement extends HTMLElement {
    public static tag = "loader-button";
    private _button: HTMLButtonElement; 
    private _shadow: HTMLElement;
    private _busyPromise: Promise<Animation>;

    static get observedAttributes() {
        return ["busy"];
    }

    async attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === "busy") {
            if (newVal === "")
                await this.animateBusy();
            else
                await this.animateUnBusy();
        }   
        
    }

    private animateBusy = async () => { 
        this._busyPromise = new Promise<Animation>(async resolve => { 
            await this._shadow.animate([
                { opacity: 0 },
                { opacity: 1 }
            ], { duration: 300, easing: "ease-in-out", fill: "forwards" }).finished;

            this._button.style.display = "none";

            await this._shadow.animate([
                { width: "40px" }
            ], { duration: 500, easing: "ease-in-out", fill: "forwards" }).finished;

            resolve(this._shadow.animate([
                { transform: "rotateY(360deg)" }
            ], { duration: 500, easing: "ease-in-out", fill: "forwards", iterations: Infinity }));
        });
    }

    private animateUnBusy = async () => { 
        const animation = await this._busyPromise;

        animation.effect?.updateTiming({ duration: 500, easing: "ease-in-out", fill: "forwards", iterations: 1 });
        await animation.finished;
        animation.cancel();

        await this._shadow.animate([
            { width: "250px" }
        ], { duration: 500, easing: "ease-in-out", fill: "forwards" }).finished;
        
        this._button.style.display = "flex";

        await this._shadow.animate([
            { opacity: 0 }
        ], { duration: 300, easing: "ease-in-out", fill: "forwards" }).finished;
    }

    connectedCallback() {
        this.innerHTML = this.view();
        this._button = this.querySelector("button");
        this._shadow = this.querySelector(".shadow");

        //this.addEventListener("click", () => this.toggleAttribute("busy"));
    }

    private view = () => `
        <button type=button name=${this.getAttribute("name")}>${this.getAttribute("text")}</button> 
        <div class=shadow></div>
    `;

}

customElements.define(LoaderButtonElement.tag, LoaderButtonElement);