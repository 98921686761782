import "./CodeBoxElement.less";

export class CodeBoxElement extends HTMLElement { 
    static tag = "code-box";

    connectedCallback() { 
        this.addEventListener("click", this.copyCode);
    }

    private copyCode = async () => { 
        try {
            await window.navigator.clipboard.writeText(this.innerText);

            const message = document.createElement("div");
            message.innerText = "Copied!";

            this.insertAdjacentElement("beforeend", message);

            setTimeout(() => {
                message.style.opacity = "0";
                setTimeout(() => {
                    message.remove();
                 }, 1000);
             }, 1000);
        }
        catch (e) { 
            console.error(e);
        }
    }


}

customElements.define(CodeBoxElement.tag, CodeBoxElement);