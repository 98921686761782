import { LoaderButtonElement } from 'Element/LoaderButtonElement';
import "./GameCodeElement.less";
import { CodeBoxElement } from './CodeBoxElement';
import * as party from "Library/party-js";
import { VisitorStore } from 'Feature/Visitor';
import { LogStore } from 'Feature/Logging/LogStore';

export class GameCodeElement extends HTMLElement {
    static tag = "game-code";
    private _loaderButton: LoaderButtonElement;
    private _email: HTMLInputElement;
    private _errorMessage: HTMLElement;
    private _form: HTMLFormElement;

    private campaignId = () => this.getAttribute("campaign-id");
    
    connectedCallback() {
        //this.classList.add("box");
        this.innerHTML = this.view();

        party.confetti(this, {
            count: 180
        });

        this._form = this.querySelector("form");
        this._form.addEventListener("submit", this.formSubmit);
        this._email = this.querySelector("input[type=email]");
        this._errorMessage = this.querySelector(".error-message");
        this._loaderButton = this.querySelector(LoaderButtonElement.tag);
        this._loaderButton.addEventListener("click", this.submitEmail);

        setTimeout(() => {
            this._email.focus();
        }, 200);
    }

    private formSubmit = async (event: Event) => { 
        event.preventDefault();
        await this.submitEmail();
    }

    private disableAllInputs = () => { 
        this._form.querySelectorAll("input").forEach(input => input.disabled = true);
    }

    private enableAllInputs = () => { 
        this._form.querySelectorAll("input").forEach(input => input.disabled = false);
    }

    private submitEmail = async () => {
        LogStore.instance.logInformation("Entered email: " + this._email.value, {
            page: window.location.pathname,
            title: document.title,
            referrer: document.referrer,
            eventKind: "page-hit",
            email: this._email.value,
            campaignId: this.campaignId(),
            notification: true,
            visitorId: VisitorStore.instance.visitorId,
            visitorSessionId: VisitorStore.instance.visitorSessionId
        });


        this._loaderButton.toggleAttribute("busy", true);
        this.disableAllInputs();

        const response = await fetch("/gamecode", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: this._email.value,
                campaignId: this.campaignId(),
                visitorId: VisitorStore.instance.visitorId,
                visitorSessionId: VisitorStore.instance.visitorSessionId
            })
        });

        if (!response.ok) {
            this._errorMessage.innerText = "Something went wrong. Please try again.";
            this._loaderButton.toggleAttribute("busy", false);
            return;
        }


        const model = await response.json() as GameCodeResponse;

        this.innerHTML = this.successView(model);

        this._loaderButton.toggleAttribute("busy", false);
        this.enableAllInputs();
    }

    private view = () => {
        return `
            <div>
                <form>
                <h1>🎉 Congratulations!</h1>
                
                <p>You solved the puzzle! You deserve a prize.</p>
                <p><b>Would you like access to more puzzles in our short online escape room experience, <i>JP Hansen?</i></b></p>
                <p>We make online escape room experiences. <i>JP Hansen</i> is our demo room, so it is shorter than a normal 1 hour room, but we think you will have fun. We recommend that you gather a few friends to join you on this adventure.</p>
                
                <p>Enter your email address below to unlock the game and we will send you a link to play.</p>
                <input type="email" placeholder="someone@somewhere.com"></input>
                <p class="error-message"></p>
                <${LoaderButtonElement.tag} text="Submit">Submit</${LoaderButtonElement.tag}>
                
                <p>(We promise to keep your email a secret and not bother you very often)</p>
                </form>
            </div>
            <div>
                
                <h1>See the trailer here ...</h1>
                <video src="${require("../Games/JPHansen/JP-Hansen-promo-1.mp4")}" controls poster="${require("../Games/JPHansen/Images/promo-video-poster.jpg")}"></video>
            </div>

        `;
    }

    private successView = (model: GameCodeResponse) => {
        return `
            <h1>Thanks!</h1>
            <p>We have sent you an email with a code and link to the game.</p>

            <p>Your game code is</p>
            
            <${CodeBoxElement.tag}>${model.code}</${CodeBoxElement.tag}>

            <p>(One code is enough for the whole team. Just share it with your team members)</p>

            <p>Please note that this game is exclusively optimized for desktop platforms.</p>

            <p>Click <a href="${model.gameUrl}">here</a> to play now.</a></p>

           
            <p>Enjoy!</p>
        `;
    }
}

interface GameCodeResponse {
    code: string;
    gameName: string;
    gameUrl: string;
    codeUrl: string;
}

customElements.define(GameCodeElement.tag, GameCodeElement);