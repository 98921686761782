import "./GiftcardPreviewElement.less";
import { Order, OrderStore } from "./OrderStore";

class GiftcardPreviewElement extends HTMLElement { 
    static tag = "giftcard-preview";
    private _svgLoaded = false;

    connectedCallback() { 
        this.classList.add("box");
        this.toggleAttribute("loading", true);
        this.innerHTML = this.loaderView();
      
        const order = OrderStore.instance.getOrder();

        this.toggleAttribute("hidden", order?.isGift === false);
      

        OrderStore.instance.addEventListener("refresh", this.onRefresh);
        OrderStore.instance.addEventListener("gift-message-updated", this.onMessageUpdated);

        if (order.isGift) 
            this.showGiftMessage(order?.giftMessage ?? "");
 
    }

    private loaderView = () => `
        <div class="loader">${require("./Images/loader.svg")}</div>
    `;


    private loadRemoteSVG = async () => {     
        console.log("initializer"); 
        this.toggleAttribute("loading", true);

        const giftcardPath = require("!!file-loader!./Images/giftcard-template.svg").default;

        try { 
            const response = await fetch(giftcardPath);
            this.innerHTML = await response.text();
        } finally { 
            this._svgLoaded = true;
            this.toggleAttribute("loading", false);
        }
    }


    private onMessageUpdated = (event: CustomEvent<string>) => { 
        this.showGiftMessage(event.detail);
    }

    private showGiftMessage = async (message: string) => { 
        if (!this._svgLoaded)
            await this.loadRemoteSVG(); 

        this.querySelector("#user-text").innerHTML = this.formatText(message ?? "Enter a giftcard message...");
    }

    private onRefresh = (event: CustomEvent<Order>) => { 
        console.log("refresh", event.detail);

        this.toggleAttribute("hidden", !event.detail.isGift);

        this.showGiftMessage(event.detail.giftMessage);  
    }

    private formatText = (text: string) => { 
        // replace newlines with <br>
        return text.replace(/\n/g, "<br>");
    }
}

customElements.define(GiftcardPreviewElement.tag, GiftcardPreviewElement);