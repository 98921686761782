import "./SpotTheDifferencesElement.less";
import "./SpotFinderElement.less";
import { Spot, SpotStore } from "./SpotStore";

export class SpotFinderElement extends HTMLElement { 
    static tag = "spot-finder"; 
    private get puzzleId() { 
        return this.getAttribute("puzzle-id");
    }

    connectedCallback() {
        this.innerHTML = this.view();
        this.addEventListener("pointerup", this.onPointerUp, { passive: true });
        this.addEventListener("pointerdown", this.onPointerDown, { passive: true }); 

        SpotStore.instance.addEventListener("spot-added", this.onSpotAdded);
        SpotStore.instance.addEventListener("spot-removed", this.onSpotRemoved);
        SpotStore.instance.addEventListener("puzzle-active", this.onPuzzleActive);

        const spots = SpotStore.instance.getSpots(this.puzzleId);
        spots.forEach(this.addSpot); 
    }

    disconnectedCallback() { 
        this.removeEventListener("pointerup", this.onPointerUp);
        this.removeEventListener("pointerdown", this.onPointerDown);

        SpotStore.instance.removeEventListener("spot-added", this.onSpotAdded);
        SpotStore.instance.removeEventListener("spot-removed", this.onSpotRemoved);
        SpotStore.instance.removeEventListener("puzzle-active", this.onPuzzleActive);
    }

    private onPuzzleActive = (event: CustomEvent<boolean>) => { 
        this.toggleAttribute("active", event.detail);
    }

    private onSpotAdded = (event: CustomEvent<Spot>) => { 
        const spot = event.detail;
        this.addSpot(spot);
    }

    private addSpot = (spot: Spot) => { 
        const foundSpotElement = new FoundSpotElement();

        foundSpotElement.toggleAttribute("correct", spot.correct === true);

        foundSpotElement.id = spot.id;
        foundSpotElement.style.left = `${spot.left}%`;
        foundSpotElement.style.top = `${spot.top}%`;
        this.appendChild(foundSpotElement);
    }

    private onSpotRemoved = (event: CustomEvent<Spot>) => { 
        const spot = this.querySelector(`[id="${event.detail.id}"]`);

        if (!spot) { 
            return;
        }

        this.removeChild(spot);
    }

    private _position: { x: number, y: number };


    private onPointerDown = (event: MouseEvent) => { 
        console.log("onPointerDown", event);

        this._position = {
            x: event.clientX,
            y: event.clientY
        };
    }

    private onPointerUp = (event: MouseEvent) => { 
        const deltaX = Math.abs(event.clientX - this._position.x);
        const deltaY = Math.abs(event.clientY - this._position.y);

        if (deltaX > 10 || deltaY > 10) 
            return;
        
        const spot = event.target as HTMLElement;
        if (spot instanceof FoundSpotElement) { 
            SpotStore.instance.remove(this.puzzleId, spot.id);
            return;
        }

        const percentLeft = (event.offsetX / this.clientWidth) * 100;
        const percentTop = (event.offsetY / this.clientHeight) * 100;

        SpotStore.instance.add(this.puzzleId, {
            left: percentLeft,
            top: percentTop
        });
    }

    private view = () => `
        <img src="${this.getAttribute("source")}" />
    `;
}

customElements.define(SpotFinderElement.tag, SpotFinderElement);


class FoundSpotElement extends HTMLElement { 
    static tag = "found-spot";
}

customElements.define(FoundSpotElement.tag, FoundSpotElement);