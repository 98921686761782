import { DiscountCodeElement } from "Feature/DiscountCodes/DiscountCodeElement";
import "./CheckoutControlElement.less";
import { Order, OrderStore } from "./OrderStore";
import { OrderViewerElement } from "./OrderViewerElement";
import { OrderDetailsElement } from "./OrderDetailsElement";
import { OrderPaymentElement } from "./OrderPaymentElement";
import { GiftcardDetailsElement } from "./GiftcardDetailsElement";
import { StripePaymentElement } from "../Stripe/StripePaymentElement";

export class CheckoutControlElement extends HTMLElement { 
    static tag = "checkout-control";    
    private _header: HTMLElement;

    private updateHeader = () => { 
        const order = OrderStore.instance.getOrder();

        if (order.completed) {
            this._header.innerHTML = "Thank you for your order!";
            return;
        }

        this._header.innerHTML = order.isGift ? "Order Gift Card" : "Order Escape Room";
    }

    connectedCallback() {
        this.innerHTML = this.view();
        this._header = this.querySelector("header");
        const order = OrderStore.instance.getOrder();
        this.setAttribute("mode", order.isGift ? "gift" : "self");
        this.updateHeader();
        OrderStore.instance.addEventListener("refresh", this.onRefresh);
    }

    disconnectedCallback() {
        OrderStore.instance.removeEventListener("refresh", this.onRefresh);
    }

    private view = () => `
        <header></header>
        <div class="columns">
            <div>
                <${OrderViewerElement.tag}></${OrderViewerElement.tag}>
                <${DiscountCodeElement.tag}></${DiscountCodeElement.tag}>
                <flip-button></flip-button>
                <${GiftcardDetailsElement.tag}></${GiftcardDetailsElement.tag}>
                <${OrderDetailsElement.tag}></${OrderDetailsElement.tag}>
                <${OrderPaymentElement.tag} stripe-key="${this.getAttribute("stripe-key")}"></${OrderPaymentElement.tag}>
            </div>
            <div class="vertical-spacer"></div>
            <giftcard-preview></giftcard-preview>
        </div>
    `;

    private onRefresh = async (event: CustomEvent<Order>) => { 
        this.setAttribute("mode", event.detail.isGift ? "gift" : "self");
        this.updateHeader();
    }
}

customElements.define(CheckoutControlElement.tag, CheckoutControlElement);