import { LoaderButtonElement } from 'Element/LoaderButtonElement';
import { GameCodeElement } from 'Feature/Gamecode/GameCodeElement';
import { VisitorStore } from 'Feature/Visitor';
import "./AnswerCheckerElement.less";
import { PostcardViewerElement } from './PostcardViewerElement';

export class AnswerCheckerElement extends HTMLElement {
    static tag = "answer-checker";
    private _loaderButton: LoaderButtonElement;
    private _codeword: HTMLInputElement;

    private _codeWords: HTMLInputElement[];

    private _errorMessage: HTMLElement;
    private _form: HTMLFormElement;
    private _hintsArea: HTMLElement;

    connectedCallback() {  
        this._form = this.querySelector("form");
        this._form.addEventListener("submit", this.formSubmit);
        this._codeword = this.querySelector("input[type=text]");

        this._codeWords = Array.from(this.querySelectorAll("input[type=text]"));

        this._errorMessage = this.querySelector(".error-message");
        this._loaderButton = this.querySelector(LoaderButtonElement.tag);
        this._loaderButton.addEventListener("click", this.checkAnswer);
        this._hintsArea = this.querySelector("[name=hints-area]");
    }

    private campaignId = () => this.getAttribute("campaign-id");

    private formSubmit = async (event: Event) => { 
        event.preventDefault();
        await this.checkAnswer();
    }

    private disableAllInputs = () => { 
        this._form.querySelectorAll("input").forEach(input => input.disabled = true);
    }

    private enableAllInputs = () => { 
        this._form.querySelectorAll("input").forEach(input => input.disabled = false);
    }

    private onToggle = async (event: CustomEvent) => { 
        if (!(event.target instanceof HTMLElement)) { 
            return;
        }

        const hint = event.target.querySelector("summary").innerText;

        await fetch(`/activity/${this.campaignId()}/hint`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                hint: hint,
                visitorId: VisitorStore.instance.visitorId,
                visitorSessionId: VisitorStore.instance.visitorSessionId
            })
        });
    }

    private getCodeWord = () => { 
        var word = "";

        for (const codeWord of this._codeWords) {
            word += " " + (codeWord.value ?? "").trim();
        }

        return word;
    }

    private checkAnswer = async () => { 
        this._loaderButton.toggleAttribute("busy", true);
        this.disableAllInputs();

        const response = await fetch(`/activity/${this.campaignId()}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                word: this.getCodeWord(),
                visitorId: VisitorStore.instance.visitorId,
                visitorSessionId: VisitorStore.instance.visitorSessionId
            })
        });

        if (!response.ok) { 
            this._errorMessage.innerText = "Something went wrong. Please try again.";
            this._loaderButton.toggleAttribute("busy", false);

            this.enableAllInputs();
            return;
        }


        const model = await response.json() as PostcardResponse;

        if (model.correct) {
            this.showSuccess();
        } else { 
            this._errorMessage.innerText = "That's not right. Try again.";
            this._hintsArea.innerHTML = this.hintsView(model.hints);

            this._hintsArea.querySelectorAll("details").forEach(details => {
                details.addEventListener("toggle", this.onToggle);
            });
        }

        this._loaderButton.toggleAttribute("busy", false);
        this.enableAllInputs();
    }

    private hintsView = (hints: string[]) => `
        <h1>Do you need a hint?</h1>

        ${hints.map( (hint, index) => `
            <details>
                <summary>Hint ${index+1}</summary>
                ${hint}
            </details>
        `).join("")}
    `;

    private showSuccess = () => { 

        document.querySelector(PostcardViewerElement.tag).remove();

        var gameCodeElement = new GameCodeElement();
        gameCodeElement.setAttribute("campaign-id", this.campaignId());
        this.replaceWith(gameCodeElement);
    }
}

customElements.define(AnswerCheckerElement.tag, AnswerCheckerElement);


interface PostcardResponse { 
    correct: boolean;
    hints: string[];
}