import EventTarget from "@ungap/event-target";

export interface EventMap {
    "refresh": Order;
    "gift-message-updated": string;
}

export class OrderStore {
    private _order: Order;
    private _eventTarget = new EventTarget();
    private static _instance = new OrderStore();
    public static get instance() { 
        return OrderStore._instance
    }
    
    public get orderId() { 
        // parse url and return last segment
        const url = new URL(window.location.href);
        const path = url.pathname.split("/");
        return path[path.length - 1];
    }

    public getOrder() { 
        const bootstrapOrder = document.querySelector("script[type='application/json+order']") as HTMLScriptElement;
        if (bootstrapOrder) { 
            const json = bootstrapOrder.innerText;
            bootstrapOrder.remove();

            this._order = JSON.parse(json);
            console.log("order", this._order);
        }

        return this._order;
    }

    public setInMemoryGiftMessage(message: string) { 
        this._order.giftMessage = message;
        this.emit("gift-message-updated", message);
    }

    private refresh = async () => { 
        const response = await fetch(`/api/ordering/${this.orderId}`);

        if (response.ok) { 
            const previous = this._order;
            this._order = await response.json() as Order;
            this.emit("refresh", this._order);
        }
    }

    public async removeDiscountCode(code: string) : Promise<string> {
        const response = await fetch(`/api/ordering/${this.orderId}/discount`, {
            method: "DELETE",
            body: JSON.stringify({ code }),
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (response.ok) { 
            this.refresh();
        }

        return await response.text();
    } 

    public async addDiscountCode(code: string) : Promise<string> { 
        const response = await fetch(`/api/ordering/${this.orderId}/discount`, {
            method: "POST",
            body: JSON.stringify({ code }),
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (response.ok) { 
            await this.refresh();
        }

        return await response.text();
    }

    public showGiftCardOption(order: Order) {
        return!order.orderlines.some(m => m.productId === "product_01hbzc8vq8e2es219n0731gm9c");
    }

    public async update(action: (order: Order) => void) {
        const order = this.getOrder();
        action(order);

        const response = await fetch(`/api/ordering/${this.orderId}/details`, {
            method: "POST",
            body: JSON.stringify({
                email: order.email,
                isGift: order.isGift,
                giftMessage: order.giftMessage
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) { 
            this.refresh();
        }

        return await response.text();
    }

    public async complete() { 
        const response = await fetch(`/api/ordering/${this.orderId}/complete`, {
            method: "POST",
            //body: JSON.stringify(payment),
            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) { 
            this.refresh();
        }

        return await response.text();
    }

    public async addPayment(payment: Payment) { 
        const response = await fetch(`/api/ordering/${this.orderId}/payment`, {
            method: "POST",
            body: JSON.stringify(payment),
            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) { 
            this.refresh();
        }

        return await response.text();
    }

    addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this._eventTarget.addEventListener(type, listener, options);
    }

    removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this._eventTarget.removeEventListener(type, listener, options);
    }

    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this._eventTarget.dispatchEvent(new CustomEvent(type, { detail: value }));
    }

    public static hasDiscountCode(order: Order) { 
        return order.orderlines.filter(m => m.discountCodeId).length > 0;
    }

    public static hasEmail(order: Order) {
        return !!order.email;
    }

    public static readyForPayment(order: Order) {
        return order.completed === false &&  OrderStore.hasEmail(order) && !order.completed;
    }
}

export interface Order { 
    id: string;
    total: number;
    orderlines: Orderline[];
    email?: string;
    last4?: string;
    cardType: string;
    completed: boolean;
    gameCode?: string;
    gameCodeUrl?: string;
    summary?: string;
    isGift?: boolean;
    giftMessage?: string;
}

export interface Orderline { 
    amount: number;
    name: string;
    description: string;
    discountCodeId?: string;
    productId?: string;
}

export interface Payment { 
    status: string;
    transactionId: string;
}