import "./StatusPanelElement.less";

class StatusPanelElement extends HTMLElement { 
    static tag = "status-panel";

    connectedCallback() { 
        console.log("StatusPanelElement connected");
        this.updateGames([]);
        this.refresh();
    }

    private updateGames(statusChecks: StatusCheck[]) { 
        const games = Array.from(this.querySelectorAll(".game"));
        for (const game of games) {
            const gameId = game.id;
            const check = statusChecks.find(check => check.name === gameId);

            const status = check?.status ?? "Unknown";
            game.setAttribute("status", status);
            game.querySelector(".status").innerHTML = this.statusView(status);
        }
    }

    private refresh = async () => { 
        const response = await fetch("/healthchecks-api")
        const checks: StatusCheck[] = await response.json();

        this.removeAttribute("busy");
        this.updateGames(checks ?? []);
        setTimeout(this.refresh, 10000);
    }

    private statusView = (status: Status) => { 
        switch (status) {
            case "Healthy": return require("./Images/ok-icon.svg");
            case "Unhealthy": return require("./Images/error-icon.svg");
            case "Unknown": return require("./Images/unknown-icon.svg");
        }
    }
}

customElements.define(StatusPanelElement.tag, StatusPanelElement);

type Status = "Healthy" | "Unhealthy" | "Unknown";

interface StatusCheck { 
    status: "Healthy" | "Unhealthy";
    name: string;

}