import "./GiftcardDetailsElement.less";
import { Order, OrderStore } from "Feature/Ordering/OrderStore";
import { LoaderButtonElement } from "Element/LoaderButtonElement";

export class GiftcardDetailsElement extends HTMLElement { 
    static tag = "giftcard-details";
    private _textarea: HTMLTextAreaElement;
    static _maxCharacters = 160;
    static _maxLines = 4;
    private _counter: HTMLElement;


    connectedCallback() { 
        const order = OrderStore.instance.getOrder();

        console.log("giftcard details", order);

        this.toggleAttribute("hidden", !order.isGift);

        this.innerHTML = this.view(order?.giftMessage ?? "");
        this._counter = this.querySelector("[name=counter]");
        this._textarea = this.querySelector("textarea");


        this._textarea.readOnly = order.completed;

        this.toggleAttribute("completed", order.completed);

        this._textarea.addEventListener("input", this.onInput);

        this._textarea.addEventListener("blur", this.updateOrder);

        OrderStore.instance.addEventListener("refresh", this.onRefresh);
    }

    disconnectedCallback() { 
        OrderStore.instance.removeEventListener("refresh", this.onRefresh);
    }

    private onInput = () => { 
        OrderStore.instance.setInMemoryGiftMessage(this._textarea.value);
        this.updateCounter();
    }

    private updateOrder = () => { 
        console.log("blur");
        OrderStore.instance.update(order => order.giftMessage = this._textarea.value);
    }

    private updateCounter = () => { 
        let value = this._textarea.value;
        let lines = value.split("\n").length;
        let characters = value.length;

        

        if (lines > GiftcardDetailsElement._maxLines) { 
            // remove last line feed until we're back to 4 lines
            while (lines > GiftcardDetailsElement._maxLines) { 
                value = value.substr(0, value.length - 1);
                characters--;

                lines = value.split("\n").length;
            }

            this._textarea.value = value;
        }

        if (characters > GiftcardDetailsElement._maxCharacters) { 
            this._textarea.value = value.substr(0, GiftcardDetailsElement._maxCharacters);
        }

        value = this._textarea.value;
        characters = value.length;

        this._counter.innerHTML = `${characters} / ${GiftcardDetailsElement._maxCharacters}`;
    }

    private onRefresh = (event: CustomEvent<Order>) => { 
        console.log("refresh", event.detail.isGift);
        this.toggleAttribute("hidden", !event.detail.isGift);
        this.toggleAttribute("completed", event.detail.completed);

        this._textarea.readOnly = event.detail.completed;
    }

    private view = (message: string) => `
        <div class="box details">
            <textarea placeholder="Enter a giftcard message...">${message}</textarea>
            <div name="counter">0 / ${GiftcardDetailsElement._maxCharacters}</div>
        </div>
    `;
}

customElements.define(GiftcardDetailsElement.tag, GiftcardDetailsElement);