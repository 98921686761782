import { VisitorStore } from "Feature/Visitor";
import { LogStore } from "./LogStore";

const logStore = LogStore.instance;

export { logStore };

window.addEventListener("error", async (event: ErrorEvent) => {
    await logStore.logError(event.error);
    console.log(event);
});        

window.addEventListener("unhandledrejection", async (event: PromiseRejectionEvent) => {
    await logStore.logError(event.reason, { event: "unhandledrejection" });
    console.log(event);
});

logStore.decorate(async (properties: { [id: string] : any; }) => {
    const user = window.localStorage.getItem("player-name") as string;
    properties["_userId"] = user;
});

logStore.decorate(async (properties: { [id: string] : any; }) => {
    if (properties.visitorId)
        return;

    const visitorId = await VisitorStore.instance.visitorId;
    properties["visitorId"] = visitorId;
});

logStore.decorate(async (properties: { [id: string] : any; }) => {
    const _navigator = {
        appName: window.navigator.appName,
        appCodeName: window.navigator.appCodeName,
        appVersion: window.navigator.appVersion,
        cookieEnabled: window.navigator.cookieEnabled,
        languages: window.navigator.languages,
        oscpu: (<any>window.navigator).oscpu,
        platform: window.navigator.platform,
        product: window.navigator.product,
        webdriver: window.navigator.webdriver
    };
 
    properties["navigator"] = _navigator;
});


// a test
LogStore.instance.logInformation("Opened page", {
    page: window.location.pathname,
    title: document.title,
    referrer: document.referrer,
    eventKind: "page-hit"
});

