import { FormGroupElement } from 'Feature/Forms';
import "./NotifyMeElement.less";
import { UserPromptElement } from 'Feature/Dialog';

export class NotifyMeElement extends HTMLElement { 
    public static tag = "notify-me";
    private _signupButton: HTMLButtonElement;

    connectedCallback() { 
        this.innerHTML = this.signupView();
        this._signupButton = this.querySelector("button");
        this._signupButton.addEventListener("click", this.signup);

        setTimeout(this.checkForToken);
    }

    private checkForToken = async () => { 
        const urlSearchParameters = new URLSearchParams(window.location.search);

        if (urlSearchParameters.has("confirm")) { 
            await this.confirm(urlSearchParameters.get("confirm"));
            window.history.replaceState({}, document.title, "/");
        }

        

        if (urlSearchParameters.has("unsubscribe")) { 
            await this.unsubscribe(urlSearchParameters.get("unsubscribe"));
            window.history.replaceState({}, document.title, "/");
        }
    }

    private signup = async () => { 
        this.toggleAttribute("busy", true);

        try {

            const response = await fetch("/api/notify-me", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: this.querySelector("form-group[name=email]").getAttribute("value"),
                    name: this.querySelector("form-group[name=name]").getAttribute("value")
                })
            });

            if (response.ok) {
                this.clear();
                UserPromptElement.show("Thank you for signing up!", "Keep an eye out for our confirmation email.", [
                    { id: "ok", title: "OK", intent: "primary" }
                ]);
            } else { 

                let text;
                try { 
                    text = await response.text();
                } catch (e) { 
                    console.log("Unable to parse");
                }

                if (text.indexOf("already signed up") !== -1) {
                    this.clear();
                    UserPromptElement.show("This email is already signed up.", "Thank you!", [
                        { id: "ok", title: "OK", intent: "primary" }
                    ]);
                } else { 
                    UserPromptElement.show("Unknown error occured while trying to sign up.", "Please try again!", [
                        { id: "ok", title: "OK", intent: "primary" }
                    ]);
                }
            }
        } finally { 
            this.toggleAttribute("busy", false);
        }
    }

    private confirm = async (token: string) => { 
        this.toggleAttribute("busy", true);

        try {

            const response = await fetch("/api/notify-me/confirm/" + token, {
                method: "POST"
            });

            if (response.ok) {
                this.clear();
                UserPromptElement.show("Email confirmed", "We will notify you when new games are up!", [
                    { id: "ok", title: "OK", intent: "primary" }
                ]);
            } else { 

                let text;
                try { 
                    text = await response.text();
                } catch (e) { 
                    console.log("Unable to parse");
                }

                if (text.indexOf("not found") !== -1) {
                    this.clear();
                    UserPromptElement.show("Newsletter subscription not found.", "Please try signing up again!", [
                        { id: "ok", title: "OK", intent: "primary" }
                    ]);
                } else { 
                    UserPromptElement.show("Unknown error occured while trying to confirm subscription.", "Please try signing up again!", [
                        { id: "ok", title: "OK", intent: "primary" }
                    ]);
                }
            }
        } finally { 
            this.toggleAttribute("busy", false);
        }
    }

    private unsubscribe = async (token: string) => { 
        this.toggleAttribute("busy", true);

        try {

            const response = await fetch("/api/notify-me/unsubscribe/" + token, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: token
                })
            });

            if (response.ok) {
                this.clear();
                UserPromptElement.show("Email unsubscibed", "You are now unsubscribed.", [
                    { id: "ok", title: "OK", intent: "primary" }
                ]);
            }
        } finally { 
            this.toggleAttribute("busy", false);
        }
    }

    private clear = () => { 
        this.querySelector("form-group[name=email] input").setAttribute("value", "");
        this.querySelector("form-group[name=name] input").setAttribute("value", "");
    }


    private signupView = () => `
        <${FormGroupElement.tag}
            name="name"
            label="Your name" 
            autocomplete=name
            min-length=2
            max-length=250></form-group>

        </${FormGroupElement.tag}>

        <${FormGroupElement.tag}
            name=email
            label=Email 
            autocomplete=email
            min-length=3
            max-length=250
            type=email></form-group>

        </${FormGroupElement.tag}>

        <button type=button>Subscribe</button>
    `;
}

customElements.define(NotifyMeElement.tag, NotifyMeElement);