export type Environment = "production" | "test" | "local";

export class UrlProvider {
    static get root() {
        return  window.location.origin;
    }

    public static get environment() : Environment {
        
        const prefix = window.location.host.toLowerCase().split(".")[0].split(":")[0];
        
        switch (prefix) {
            case "localhost": return "local";
            case "test": return "test";
            default:
                return "production";
        }
        
    }
}