import "./UserPromptElement.less";

export class UserPromptElement extends HTMLElement {
    public options: PromptOption[];

    connectedCallback() {
        this.innerHTML = this.view();
        this.addEventListener("click", this.clickHandler);
    }

    static async show(caption: string, text: string, options: PromptOption[], closeHandle?: CloseHandle) : Promise<string> {
        return new Promise( (resolve, reject) => {
            const element = new UserPromptElement();
            element.options = options;
            element.setAttribute("caption", caption);
            element.setAttribute("text", text);
            element.toggleAttribute("open", true);
            document.body.append(element);

            element.addEventListener("decided", (event: CustomEvent<string>) => {
                resolve(event.detail);
            }, { once: true });

            if (closeHandle)
                closeHandle.callback = () => {
                    reject();
                    element.remove();
                };

        });
    }

    private clickHandler = (event: Event) => {
        if (!(event.target instanceof HTMLElement))
            return;

        const target = event.target as HTMLElement;

        const button = target.closest("button");
        if (!button)
            return;

        const optionId = button.getAttribute("option-id");
        if (!optionId)
            return;

        event.preventDefault();
        event.stopPropagation();

        this.dispatchEvent(new CustomEvent("decided", { bubbles: true, detail: optionId }));
        this.remove();
    }

    private view = () => `
        <div class=content>
            <header>
                <h1>${this.getAttribute("caption") || ""}</h1>
                <p>${this.getAttribute("text") || ""}</p>
            </header>
            <section>
                ${this.options.map(option => `<button option-id="${option.id}" class="${option.intent}">${option.title}</button>`).join("")}
            </section>
        </div>
    `;
}

export class CloseHandle {
    callback: () => void;

    close() {
        if (this.callback)
            this.callback();
    }
}


export interface PromptOption {
    id: string;
    title: string;
    intent: "primary" | "secondary";
}

customElements.define("user-prompt", UserPromptElement);