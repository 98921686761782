import { LoaderButtonElement } from "Element/LoaderButtonElement"; 
import { Order, OrderStore } from "./OrderStore";
import "./OrderPaymentElement.less";
import { StripePaymentElement } from "../Stripe/StripePaymentElement";

export class OrderPaymentElement extends HTMLElement {
    static tag = "order-payment"; 
    private _button: HTMLButtonElement;

    connectedCallback() {
        const order = OrderStore.instance.getOrder();
        this.updateStatus(order);

        this.checkIfPaymentIsNeeded();

        OrderStore.instance.addEventListener("refresh", this.onRefresh);

        this.innerHTML = this.view(order);
        this._button = this.querySelector(LoaderButtonElement.tag);
        this._button.addEventListener("click", this.onClick);
    }

    disconnectedCallback() {
        OrderStore.instance.removeEventListener("refresh", this.onRefresh);
        this._button.removeEventListener("click", this.onClick);
    }

    private checkIfPaymentIsNeeded = () => { 
        const order = OrderStore.instance.getOrder();
        if (!OrderStore.readyForPayment(order))
            return;

        if (order.total === 0) {
            this.insertAdjacentHTML("afterbegin", `
                <p class=wait>Please wait ...</p>
            `);
            OrderStore.instance.complete();
            return;
        }

    }

    private updateStatus = (order: Order) => { 
        const isReadyForPayment = OrderStore.readyForPayment(order);
        this.toggleAttribute("disabled", !isReadyForPayment);

        this.toggleAttribute("completed", order.completed);

        this.setAttribute("title", isReadyForPayment ? "" : "Please fill in your details first");
    }

    private onRefresh = async (event: CustomEvent<Order>) => { 
        this.updateStatus(event.detail);
        this.checkIfPaymentIsNeeded();
        this.querySelector(".wait")?.remove();
    }

    private onClick = async () => {
        this.querySelector("p")?.remove();

        this._button.toggleAttribute("busy", true);
        this._button.disabled = true;

        const order = OrderStore.instance.getOrder();

        try {
            if (order.total === 0) {
                await OrderStore.instance.complete();
            } else {
                StripePaymentElement.show(this.getAttribute("stripe-key"));
            }
        } finally {
            this._button.disabled = false;
            this._button.removeAttribute("busy");
        }
    }

    private view = (order: Order) => `
        <${LoaderButtonElement.tag} text="${order.total > 0 ? "Pay now" : "Checkout"}"></${LoaderButtonElement.tag}>
    `;
}

customElements.define(OrderPaymentElement.tag, OrderPaymentElement);